"use client";
import Layout from "./components/Layout";
import { useContext, useEffect, useState } from "react";
import {
  ReadonlyURLSearchParams,
  useRouter,
  useSearchParams,
} from "next/navigation";
import { User } from "@/app/types";
import InvestorMarketingSection from "./components/marketing/InvestorMarketing";
import { isPortfolioCompanyUserOrPartner, UserContext } from "./utils/user";
import withNoSSR from "./withNoSSR";
import {
  UsersIcon,
  DocumentChartBarIcon,
  CheckIcon,
  IdentificationIcon,
  CurrencyDollarIcon,
  UserGroupIcon,
} from "@heroicons/react/24/outline";
import { RoleOptions } from "./types";
import { classNames } from "./utils/tailwindHelper";
import { VideoCameraIcon } from "@heroicons/react/20/solid";
import Link from "next/link";
import { showNewMessage } from "@intercom/messenger-js-sdk";
import OmniSearch from "./components/search/OmniSearch";
import { makeAPIRequest } from "@/app/api/apiWrapper";
import toast from "react-hot-toast";

const Home = function () {
  const router = useRouter();
  const user = useContext(UserContext).user;
  const storeUser = useContext(UserContext).storeUser;
  const [loading, setLoading] = useState(true);
  if (!user) {
    router.push("/login");
  }

  useEffect(() => {
    const fetchData = async () => {
      const authResponse = await fetch(
        `${process.env.NEXT_PUBLIC_BACKEND_URL}/auth/whoami`,
        {
          credentials: "include",
          redirect: "error",
        }
      );

      if (authResponse.status > 400) {
        toast.error("Invalid email or password");
        return false;
      }
      const user = await authResponse.json();
      await storeUser(user);
    };
    fetchData();
  }, []);

  // Redirect user to create company if they don't have one
  useEffect(() => {
    if (user) {
      handleOnboarding(user);
    }
  }, [user]);

  const handleOnboarding = async (user: User) => {
    if (user.role === "company" && !user.company) {
      const accessRequest = await makeAPIRequest(
        "user/me/company-access-request",
        "GET"
      );
      if (accessRequest) {
        router.replace("/company/onboarding/access-requested");
      } else {
        router.replace("/company/onboarding");
      }
    }
    setLoading(false);
  };
  const adminNavigation = [
    {
      name: "All Users",
      href: "/admin/users/all",
      icon: UsersIcon,
      current: false,
    },
    {
      name: "Portfolio Users",
      href: "/admin/users/portfolio",
      icon: UsersIcon,
      current: false,
    },
    {
      name: "User Access Requests",
      href: "/admin/access-requests",
      icon: UsersIcon,
      current: false,
    },
    {
      name: "Airtable PortCo Sync",
      href: "/admin/users/airtable-portfolio",
      icon: UsersIcon,
      current: false,
    },
    {
      name: "Investor Users",
      href: "/admin/users/investor",
      icon: UsersIcon,
      current: false,
    },
    {
      name: "Ecosystem Users",
      href: "/admin/users/ecosystem",
      icon: UserGroupIcon,
      current: false,
    },
    {
      name: "Company Diligence",
      href: "/admin/diligence",
      icon: DocumentChartBarIcon,
      current: false,
    },
    {
      name: "Video Categorization",
      href: "/admin/videos",
      icon: VideoCameraIcon,
      current: false,
    },
    {
      name: "Portfolio Video List",
      href: "/admin/portfolio/videos",
      icon: VideoCameraIcon,
      current: false,
    },
    {
      name: "Pitchbook CSV Import",
      href: "/admin/pitchbook",
      icon: DocumentChartBarIcon,
      current: false,
    },
  ];

  if (!loading && user) {
    // const handleSave = async (role: role) => {
    //   const result = await updateUserInfo({
    //     id: user.id,
    //     role,
    //   });
    //   if (result) {
    //     toast.success("Updated successfully");
    //   } else {
    //     toast.error("Failed to update user information");
    //   }
    //   window.location.reload();
    // };

    ///* ;}>Throw error</button> */
    return (
      <main>
        {/* @ts-ignore */}
        <Layout>
          <div className="flex flex-col mt-12">
            <div className="flex justify-center text-2xl">
              Welcome {user?.firstName}
            </div>

            {!user.role && (
              <div className="mx-auto pt-8 max-w-2xl text-center">
                You aren&apos;t yet fully configured or associated with a
                company record. Please reach out over{" "}
                <a
                  href={`slack://user?team=T5R1Z6UE6&id=U05Q0EG9NV7`}
                  target="_blank"
                  className="font-semibold text-blue-600 hover:text-blue-500"
                >
                  slack
                </a>{" "}
                or email{" "}
                <a
                  href="mailto:support@medtechinnovator.org"
                  className="font-semibold text-blue-600 hover:text-blue-500"
                >
                  support@medtechinnovator.org
                </a>
                . We may request additional information to verify this login.
              </div>
            )}
            {user?.role == RoleOptions.Admin && (
              <div className="mx-auto">
                <div className="m-8">You are logged in as an admin.</div>
                <OmniSearch />
                <nav className="flex flex-1 flex-col" aria-label="Sidebar">
                  <ul role="list" className="-mx-2 space-y-1">
                    {adminNavigation.map((item) => (
                      <li key={item.name}>
                        <a
                          href={item.href}
                          className={classNames(
                            item.current
                              ? "bg-gray-50 text-blue-600"
                              : "text-gray-700 hover:text-blue-600 hover:bg-gray-50",
                            "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                          )}
                        >
                          <item.icon
                            className={classNames(
                              item.current
                                ? "text-blue-600"
                                : "text-gray-400 group-hover:text-blue-600",
                              "h-6 w-6 shrink-0"
                            )}
                            aria-hidden="true"
                          />
                          {item.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </nav>
                {/* <Link href="/admin">Admin</Link> */}
              </div>
            )}
            <div className="mx-auto max-w-7xl px-6 py-16 lg:px-8">
              <div className="space-y-16 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-16 sm:space-y-0 lg:gap-x-10">
                {user.openRoundsAccess && (
                  <div>
                    <div className="relative pl-16">
                      <div>
                        <div className="text-base font-semibold leading-7 text-gray-900">
                          Open Rounds
                        </div>
                        <dd className="mt-2 text-base leading-7 text-gray-600">
                          <p>
                            We provide subscribing Investors with details on
                            MedTech Innovator Portfolio companies&apos; open
                            rounds in an easy-to-use interface.
                          </p>
                        </dd>
                      </div>
                      <div>
                        <dt className="text-base font-semibold leading-7 text-gray-900">
                          <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-blue-600">
                            <CurrencyDollarIcon
                              aria-hidden="true"
                              className="h-6 w-6 text-white"
                            />
                          </div>
                        </dt>
                      </div>
                    </div>
                    <div className="isolate mx-auto mt-10 grid max-w-md grid-cols-1 gap-8">
                      <div
                        className={classNames(
                          true
                            ? "ring-2 ring-blue-600"
                            : "ring-1 ring-gray-200",
                          "rounded-3xl p-8"
                        )}
                      >
                        <h3
                          className={classNames(
                            true ? "text-blue-600" : "text-gray-900",
                            "text-lg font-semibold leading-8"
                          )}
                        >
                          Open Rounds Early Access
                        </h3>
                        <p className="mt-4 text-sm leading-6 text-gray-600">
                          Pricing will be available after the conclusion of the
                          Early Access period.
                        </p>
                        <p className="mt-6 flex items-baseline gap-x-1">
                          <span className="text-4xl font-bold tracking-tight text-gray-900">
                            $0
                          </span>
                          <span className="text-sm font-semibold leading-6 text-gray-600">
                            /mo
                          </span>
                        </p>
                        <Link
                          href="/open-rounds"
                          className={classNames(
                            true
                              ? "bg-blue-600 text-white shadow-sm hover:bg-blue-500"
                              : "text-blue-600 ring-1 ring-inset ring-blue-200 hover:ring-blue-300",
                            "mt-6 block rounded-md px-3 py-2 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                          )}
                        >
                          Navigate to Open Rounds
                        </Link>
                        <ul
                          role="list"
                          className="mt-8 space-y-3 text-sm leading-6 text-gray-600"
                        >
                          <li className="flex gap-x-3">
                            <CheckIcon
                              aria-hidden="true"
                              className="h-6 w-5 flex-none text-blue-600"
                            />
                            Accurate Information from the source
                          </li>
                          <li className="flex gap-x-3">
                            <CheckIcon
                              aria-hidden="true"
                              className="h-6 w-5 flex-none text-blue-600"
                            />
                            Structured data to filter out the noise
                          </li>
                          <li className="flex gap-x-3">
                            <CheckIcon
                              aria-hidden="true"
                              className="h-6 w-5 flex-none text-blue-600"
                            />
                            Saved filters and email notifications
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                )}

                {user?.role == RoleOptions.Company &&
                  user?.company?.cohortMembership?.length > 0 && (
                    <div className="relative pl-16">
                      <div>
                        <div className="text-base font-semibold leading-7 text-gray-900">
                          Open Round
                        </div>
                        <dd className="mt-2 text-base leading-7 text-gray-600">
                          <p>
                            Open Rounds is your secret weapon to raise capital
                            faster and from better investors. We exclusively
                            provide subscribing Investors with details on Open
                            Rounds within our Portfolio. You control exactly
                            what investors can see about your company, product,
                            and Open Round. This information should be reviewed
                            and updated as necessary. The following categories
                            of information should be completed to ensure you
                            match filtering criteria we provide investors.
                          </p>
                        </dd>
                      </div>
                      <div>
                        <dt className="text-base font-semibold leading-7 text-gray-900">
                          <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-blue-600">
                            <IdentificationIcon
                              aria-hidden="true"
                              className="h-6 w-6 text-white"
                            />
                          </div>
                        </dt>
                        <dd className="mt-2 text-base leading-7 text-gray-600">
                          <Link
                            href={`/company/${user.companyId}/open-round`}
                            className="font-semibold text-blue-600 hover:text-blue-500"
                          >
                            Open Round
                          </Link>
                        </dd>
                      </div>
                    </div>
                  )}

                {isPortfolioCompanyUserOrPartner(user) && (
                  <div className="relative pl-16">
                    <div>
                      <div className="text-base font-semibold leading-7 text-gray-900">
                        Portfolio Directory
                      </div>
                      <dd className="mt-2 text-base leading-7 text-gray-600">
                        <p>
                          Comprehensive list of all Innovator Portfolio
                          companies, summaries, videos and contact information.
                          This directory is powered by live data provided by the
                          companies themselves.
                        </p>
                      </dd>
                    </div>
                    <div>
                      <dt className="text-base font-semibold leading-7 text-gray-900">
                        <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-blue-600">
                          <IdentificationIcon
                            aria-hidden="true"
                            className="h-6 w-6 text-white"
                          />
                        </div>
                      </dt>
                      <dd className="mt-2 text-base leading-7 text-gray-600">
                        <Link
                          href={`/portfolio-directory`}
                          className="font-semibold text-blue-600 hover:text-blue-500"
                        >
                          Navigate to directory
                        </Link>
                      </dd>
                    </div>
                  </div>
                )}
              </div>
            </div>

            {user?.role == RoleOptions.Company && (
              <>
                {user?.company?.cohortMembership?.length > 0 ? (
                  <div>
                    {/* <div>
                            <dt className="text-base font-semibold leading-7 text-gray-900">
                              Slack - Quick Questions and Conversations
                            </dt>
                            <dd className="mt-2 text-base leading-7 text-gray-600">
                              <p>
                                Medtech Innovator Slack is the best place to
                                communicate in real time with other your
                                year&apos;s Cohort, all Alumni, and the extended
                                MedTech / BioTools Innovator ecosystem including
                                our partners, investors, and program judges. The
                                channels are invite-only channels, please let us
                                know if you did not receive an invitation to the
                                channels mentioned here.
                              </p>
                              <p>
                                <a
                                  href="https://join.slack.com/t/medtechinnovator/signup"
                                  className="font-semibold text-blue-600 hover:text-blue-500"
                                  target="_blank"
                                >
                                  Join Here
                                </a>
                              </p>
                            </dd>
                          </div> */}
                  </div>
                ) : (
                  <div className="flex flex-col items-center py-8 mx-auto">
                    <p className="">2025 Applications are not yet open.</p>
                    <p>
                      <Link
                        className="font-semibold text-blue-600 hover:text-blue-500"
                        href="https://share.hsforms.com/17ACHRg1pST2VZwFVtNu9QAq3ldm"
                      >
                        Join the waitlist to get notified
                      </Link>
                    </p>
                  </div>
                )}
              </>
            )}

            {user?.role && user.role == RoleOptions.Investor && (
              <div>
                {/* {!user.company ? (
                  <div className="flex py-8">
                    <div className="mx-auto">
                      To get started, you must first create a company record.
                      <NewCompany />
                    </div>
                  </div>
                ) : ( */}
                <>
                  {!user.openRoundsAccess && (
                    <div className="flex py-8 flex-col text-center">
                      <p>You are not yet configured for Open Rounds access.</p>
                      <p>
                        Please reach out to support at{" "}
                        <a
                          href="mailto:support@medtechinnovator.org"
                          className="font-semibold text-blue-600 hover:text-blue-500"
                        >
                          support@medtechinnovator.org
                        </a>{" "}
                        or{" "}
                        <a
                          className="font-semibold text-blue-600 hover:text-blue-500 hover:cursor-pointer"
                          onClick={() => {
                            showNewMessage(
                              "Hi, I'm an investor and need help getting access to Open Rounds."
                            );
                          }}
                        >
                          chat with us
                        </a>
                        .
                      </p>
                    </div>
                  )}
                </>
                {/* )} */}
                <div className="hidden lg:block">
                  <InvestorMarketingSection />
                </div>
              </div>
            )}
          </div>
        </Layout>
      </main>
    );
  } else {
    return <></>;
  }
};
export default withNoSSR(Home);
